class LoteService {
    // http://localhost:3000
    constructor() {
        this.URI = '/api/lotes';
    }

    async getLotes() {
        const response = await fetch(this.URI);
        const lotes = await response.json();
        return lotes;
    }

    async postLote(lote) {
        const res = await fetch(this.URI, {
            method: 'POST',
            body: lote
        });
        const data = await res.json();
        console.log(data)
    }

    async deleteLote(loteId) {
        const res = await fetch(`${this.URI}/${loteId}`, {
            headers: {
                'Content-Type': 'applicaton/json'
            },
            method: 'DELETE',
        });
        const data = await res.json();
        console.log(data)
    }
}

export default LoteService;
