class WalletService {
    // http://localhost:3000
    constructor() {
        this.URI = '/api/lotes/wallets';
    }

    async getWallets(idLote) {
        const response = await fetch(this.URI+'/'+idLote);
        const wallets = await response.json();
        return wallets;
    }
}

export default WalletService;
