import './styles/app.css';

import UI from './UI'; 

document.addEventListener('DOMContentLoaded', () => {
    const ui = new UI();
    ui.renderLotes();
});

document.getElementById('form-lotes')
    .addEventListener('submit', e => {
        const nLote = document.getElementById('numLote').value;
        const cMonederos = document.getElementById('cantMonederos').value;

        const dataForm = new FormData();
        dataForm.append('nLote', nLote);
        dataForm.append('cMonederos', cMonederos);

        const ui = new UI();
        ui.addNewLote(dataForm);
        ui.renderMessage('Nuevo lote agregado', 'success', 3000);

        e.preventDefault();
    });

document.getElementById('listadoLotes')
    .addEventListener('click', e => {
        if (e.target.classList.contains('delete')) {
            const ui = new UI();
            ui.deleteLote(e.target.getAttribute('_id'));
            ui.renderMessage('Lote eliminado', 'danger', 2000);
        }
        //e.preventDefault();
    });
