import LoteService from "./services/LoteService";
const loteService = new LoteService();
const walletService = new WalletService();

import WalletService from "./services/WalletService";

const QRCode = require('qrcode');

class UI {

    async renderLotes() {
        const lotes = await loteService.getLotes();
        const lotesCardContainer = document.getElementById('listadoLotes');
        lotesCardContainer.innerHTML = '';
        const lotesQRContainer = document.getElementById('QRsContainer');
        lotesQRContainer.innerHTML = '';
        lotes.forEach(lote => {
            const div = document.createElement('div');
            div.className = '';
            div.innerHTML = `
                <div class="accordion-item">
                    <h2 class="accordion-header" id="heading${lote._id}">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse${lote._id}, #ID-${lote._id}" aria-expanded="false" aria-controls="collapse${lote._id}">
                            <span class="badge bg-primary rounded-pill">Monederos: ${lote.cMonederos}</span> &nbsp &nbsp Lote: ${lote.nLote}
                        </button>
                    </h2>
                    <div id="collapse${lote._id}" class="accordion-collapse collapse" aria-labelledby="heading${lote._id}" style="">
                        <div class="p-2">
                            <a href="#" class="btn btn-danger delete" _id="${lote._id}">Borrar</a>
                            <!-- <a href="#" class="btn rounded-pill btn-success">Pegatinas</a> -->
                        </div>
                        <ul class="accordion-body list-group" id="Monederos-${lote._id}">
                        </ul>
                    </div>
                </div>
            `;
            lotesCardContainer.appendChild(div);
            this.renderMonederos(`${lote._id}`);
            this.creardivs(`${lote._id}`, `${lote.cMonederos}`);
            this.generarQRs(`${lote._id}`);
        });
    }

    async renderMonederos(idLote) {
        const monederos = await walletService.getWallets(idLote);
        const monederosContainer = document.getElementById('Monederos-'+idLote);
        monederosContainer.innerHTML = '';
        monederos.forEach(async monedero => {
            const addr = `${monedero.addrSLP}`;
            const liMon = document.createElement('li');
            liMon.className = 'badge bg-light text-start';
            liMon.innerHTML = `${monedero.counter} - ${monedero.addrSLP}`;
            monederosContainer.appendChild(liMon);
        });
    }

    async creardivs(idLote, cantidad) {
        const paginas = Math.ceil(cantidad / 55);
        const contenedor = document.getElementById('QRsContainer');
        const filas = Math.ceil(cantidad / 5);
        const restoFilas = filas - ((paginas-1) * 11);
        var currentP = 1;
        var contador = 1;
        const nuevoLote = document.createElement('div');
        nuevoLote.className = 'accordion-collapse collapse';
        nuevoLote.id = 'ID-' + idLote;
        contenedor.appendChild(nuevoLote);
        for (let i=1; i <= paginas; i++) {
            const nuevaPagina = document.createElement('div');
            nuevaPagina.className = 'page-APLI3051';
            nuevaPagina.id = 'page-' + i;
            nuevoLote.appendChild(nuevaPagina);
            if (currentP < paginas) {
                for (let j=1; j <= 11; j++) {
                    const nuevaFila = document.createElement('div');
                    nuevaFila.className = 'sticker-row';
                    nuevaFila.id = 'fila-' + j;
                    nuevaPagina.appendChild(nuevaFila);
                    for (let k=0; k < 5; k++) {
                        const nuevaCelda = document.createElement('div');
                        nuevaCelda.className = 'sticker';
                        nuevaCelda.id = idLote+'-'+contador;
                        //nuevaCelda.innerHTML = 'ID: ' + nuevaCelda.id;
                        nuevaFila.appendChild(nuevaCelda);
                        contador++;
                    }
                }
            } else {
                for (let j=1; j <= restoFilas; j++) {
                    const nuevaFila = document.createElement('div');
                    nuevaFila.className = 'sticker-row';
                    nuevaFila.id = 'fila-' + j;
                    nuevaPagina.appendChild(nuevaFila);
                    for (let k=0; k < 5; k++) {
                        const nuevaCelda = document.createElement('div');
                        nuevaCelda.className = 'sticker';
                        nuevaCelda.id = idLote+'-'+contador;
                        //nuevaCelda.innerHTML = 'ID: ' + nuevaCelda.id;
                        nuevaFila.appendChild(nuevaCelda);
                        contador++;
                    }
                }
            }
            currentP++;
        }
    };

    async generarQRs(idLote) {
        const qrOptions = { width: 75, margin: 0 };
        var iterador = 1;
        const wallets = await walletService.getWallets(idLote);
        wallets.forEach(async wallet => {
            const contador = idLote + '-' + wallet.counter;
            //console.log(contador);
            const celda = document.getElementById(contador);
            const addr = `${wallet.addrSLP}`;
            const pubQR = await QRCode.toDataURL(addr, qrOptions);
            celda.innerHTML = `<img class="QR" src='${pubQR}' />` //Nº: ${iterador}`;
            iterador++;
        });
    };

    async addNewLote(lote) {
        await loteService.postLote(lote);
        //await this.renderMonederos(`${lote._id}`);
        //await this.creardivs(`${lote._id}`, `${lote.cMonederos}`);
        //await this.generarQRs(`${lote._id}`);
        this.renderLotes();
        this.clearLoteForm();
    }

    clearLoteForm() {
        document.getElementById('form-lotes').reset();
    }

    renderMessage(message, colorMessage, secondsToRemove) {
        const div = document.createElement('div');
        div.className = `alert alert-${colorMessage} message`;
        div.appendChild(document.createTextNode(message));

        const container = document.querySelector('.col-lg-4');
        const loteForm = document.querySelector('#accordionExample');

        container.insertBefore(div, loteForm);
        setTimeout(() => {
            document.querySelector('.message').remove();
        }, secondsToRemove);
    }

    async deleteLote(loteId) {
        await loteService.deleteLote(loteId);
        this.renderLotes();
    }

}

export default UI;